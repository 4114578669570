import React, { FC, useMemo } from 'react';
import {productPDFSrc, useTranslation} from '$shared/utils';
import { ColumnList } from '$shared/components/column-list';
import { useProduct } from '$shared/utils/product';
import { TabMenu } from '$shared/components/tab-menu';
import {
    StyledDownload,
    StyledDownloadWrapper,
    StyledSpecificationWrapper,
} from './style';
import { Icon } from '$shared/components/icon';
import { ToolTip } from '$shared/components/hover-card-tooltip/tool-tip';
import {ColumnListItemProps} from "$shared/components/column-list/column-list";

export const Specifications: FC = () => {
    const product = useProduct();
    const { translate } = useTranslation();

    const SpecificationData = useMemo(
        () =>
            product?.specifications?.map((s) => ({
                dataLabel: (
                    <StyledSpecificationWrapper>
                        {s.name}
                        {s.url && (
                            <StyledDownloadWrapper>
                                <ToolTip content={translate('generals.details')} arrow={true}>
                                    <StyledDownload href={productPDFSrc(s.url)} target="_blank">
                                        <Icon icon={'info'} color="blue" size={22} />
                                    </StyledDownload>
                                </ToolTip>
                            </StyledDownloadWrapper>
                        )}
                    </StyledSpecificationWrapper>
                ),
                dataItem: `${s.value} ${s.suffix}`,
            })),
        [translate, product?.specifications]
    );

    const productSpecificationsByType = useMemo(() => {
        const specsByType: { [type: string]: any[] } = {};

        product?.productSpecifications?.forEach((spec) => {
            if (spec.data) {
                try {
                    const parsedData = JSON.parse(spec.data);

                    if (spec.type && !specsByType[spec.type]) {
                        specsByType[spec.type] = [];
                    }

                    if (spec.type) {
                        specsByType[spec.type].push(parsedData);
                    }
                } catch (error) {
                    console.error(
                        `Error parsing specification data for type ${spec.type}:`,
                        error
                    );
                }
            } else {
                console.warn(`Specification data is undefined for type ${spec.type}`);
            }
        });

        return specsByType;
    }, [product?.productSpecifications]);

    const tabs = useMemo(() => {
        const tabsArray = [
            {
                tabName: translate('product-detail-page.specification-data.specifications'),
                content: <ColumnList listItems={SpecificationData || []} />,
            },
        ];

        Object.entries(productSpecificationsByType).forEach(([type, specs]) => {
            const specItems = specs.map((spec) => {
                return Object.entries(spec).map(([key, value]) => {
                    if (key === 'id') return null;
                    if (typeof value === 'object' && value !== null && 'value' in value) {
                        const typedValue = value as { value: string; unit?: string };
                        return {
                            dataLabel: key,
                            dataItem: `${typedValue.value} ${typedValue.unit || ''}`.trim() as React.ReactNode,
                        };
                    } else {
                        return {
                            dataLabel: key,
                            dataItem: String(value) as React.ReactNode,
                        };
                    }
                });
            });

            const flattenedSpecItems = specItems.flat();

            tabsArray.push({
                tabName: type,
                content: (
                    <ColumnList listItems={flattenedSpecItems as ColumnListItemProps[]} />),
            });
        });

        return tabsArray;
    }, [SpecificationData, productSpecificationsByType, translate]);

    if (!tabs.length) return null;
    else return <TabMenu tabs={tabs} />;
};