import { RawHtml } from '$shared/components/raw-html';
import React, {useEffect} from 'react';
import { IHeadline } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import {useCookieConsent} from "$shared/hooks/useCookieConsent";

export interface M100Props {
    /**
     * Headline
     */
    headline?: IHeadline | undefined;

    /**
     * Accordion Items
     */
    text?: { html: string };

    height?: string;

    horizontalAlignment?: string;

    verticalAlignment?: string;

    id?: string;
}

export const M100 = ({ id, text }: M100Props) => {
    if (!text?.html) {
        return null;
    }

    const { marketing, statistics } = useCookieConsent();
    const hasConsent = marketing && statistics;

    useEffect(() => {
        const iframes = document.querySelectorAll<HTMLIFrameElement>(
            `iframe.requires-cookie-consent`
        );

        if (hasConsent === undefined) {
            return;
        }

        iframes.forEach((iframe) => {
            let fallback = iframe.nextElementSibling as HTMLDivElement | null;
            if (!fallback || !(fallback instanceof HTMLDivElement)) {
                fallback = document.createElement('div');
                fallback.style.textAlign = 'center';
                fallback.style.padding = '10px';
                fallback.style.border = '1px solid #ccc';
                fallback.style.borderRadius = '5px';
                fallback.style.backgroundColor = '#f9f9f9';
                fallback.style.color = '#333';

                const title = document.createElement('p');
                title.style.margin = '0';
                title.style.fontWeight = 'bold';
                title.style.fontSize = '16px';
                title.textContent = 'YOU NEED TO CHANGE YOUR COOKIE CONSENT TO VIEW THIS CONTENT';

                const description = document.createElement('p');
                description.style.margin = '10px 0 0 0';
                description.textContent =
                    'Click the little icon in the bottom-left corner to update your settings.';

                fallback.appendChild(title);
                fallback.appendChild(description);
                iframe.parentNode?.insertBefore(fallback, iframe.nextSibling);
            }

            if (hasConsent) {
                iframe.style.display = '';
                fallback.style.display = 'none';
            } else {
                iframe.style.display = 'none';
                fallback.style.display = '';
            }
        });
    }, [hasConsent, id, text?.html]);

    return (
        <ModuleContainer id={id}>
            <RawHtml html={text.html} />
        </ModuleContainer>
    );
};
