/// Fetched 2024-01-17T09:17:41.1488428Z
/// From: https://app-kyo-cms-we-dev.azurewebsites.net/

/* eslint @typescript-eslint/no-empty-interface: "off" */

export interface ISearchResultItemViewModel extends IIdentifiable, Omit<IRenderable, 'type'> {
    type: 'searchResultItemViewModel';
    searchTitle: string;
    link: ILink;
    title: string;
    description: string;
    score: number;
}

export interface IN04MainNavigationSubItemPage
    extends IMainNavigationNode,
        Omit<IRenderable, 'type'> {
    type: 'n04MainNavigationSubItemPage';
}

export interface IN03MainNavigationWithSubItemsPage
    extends IMainNavigationNode,
        Omit<IRenderable, 'type'> {
    type: 'n03MainNavigationWithSubItemsPage';
    children?: IN04MainNavigationSubItemPage[];
}

export interface IN02MainNavigationWithCenterItemsPage
    extends INavigationTopLevelNode,
        Omit<IRenderable, 'type'> {
    type: 'n02MainNavigationWithCenterItemsPage';
    children?: IN03MainNavigationWithSubItemsPage[];
    promotions?: IPromotionBlock[];
}

export interface IN01MainNavigationWithLeftItemsPage
    extends INavigationTopLevelNode,
        Omit<IRenderable, 'type'> {
    type: 'n01MainNavigationWithLeftItemsPage';
    children?: IN02MainNavigationWithCenterItemsPage[];
}

export interface IN00MainNavigationPage extends IMainMenu, Omit<IRenderable, 'type'> {
    type: 'n00MainNavigationPage';
}

export interface IPromotionBlock extends IIdentifiable, Omit<IRenderable, 'type'>, ITheme {
    type: 'promotionBlock';
    header?: string;
    description?: string;
    link?: ILink;
    media?: IResponsiveMedia;
}

export interface ISubmenuModule extends Omit<IModule, 'type'> {
    type: 'submenuModule';
    buttonLink?: ILink;
    items?: ISubmenuItem[];
}

export interface IM220JobVacanciesModule extends Omit<IModule, 'type'> {
    type: 'm220JobVacanciesModule';
    headline?: IHeadline;
    /**
     * Used for formatting PublishedDate of Items
     */
    formattingCulture?: string;
    items?: IJobVacanciesItem[];
    noResultsText?: string;
}

export interface IM210CookieDeclarationModule extends Omit<IModule, 'type'> {
    type: 'm210CookieDeclarationModule';
}

export interface IKyoceraFrame extends Omit<IFrame, 'type'> {
    type: 'kyoceraFrame';
    header?: IKyoceraHeader;
    footer?: IKyoceraFooter;
}

export interface IKyoceraHeader extends IHeader {
    contact?: ILink;
    shop?: ILink;
    signup?: ILink;
    searchBar?: HeaderSearchBarViewModel;
    myPages?: ILink[];
}

export interface IKyoceraFooter extends IFooter {
    contact?: FooterContactBlockViewModel;
    social?: FooterSocialBlockViewModel;
    newsletter?: FooterNewsletterBlockViewModel;
    meta?: FooterMetaBlockViewModel;
    menu?: FooterMenuBlockViewModel;
}

export interface IBrand extends IIdentifiable {}

export interface IProduct
    extends IIdentifiable,
        Omit<IRenderable, 'type'>,
        ILocalizable,
        IGlobalizable {
    type: 'product';
    url?: string;
    hierarchy?: string;
    alternativeItemNumber?: string;
    originalItemNumber?: string;
    sanitizedItemNumber?: string;
    description1?: string;
    description2?: string;
    description3?: string;
    photoPath?: string;
    sketchPath?: string;
    dataSheetPaths?: string[];
    unit?: string;
    specifications?: IProductSpecification[];
    previouslyBought: boolean;
    associatedProductOriginalItemNumbers?: string[];
    extraServices?: ExtraService[];
    sparePartsProductOriginalItemNumbers?: string[];
    similarProductOriginalItemNumbers?: string[];
    promotedProduct: boolean;
    productSpecifications?: IProductSpecifications[];
}

export interface IProductGroup extends IIdentifiable, Omit<IRenderable, 'type'> {
    type: 'productGroup';
    hierarchy?: string;
    name?: string;
    imagePath?: string;
    sketchPath?: string;
    culture?: string;
    markets?: string;
    path?: string;
}

export interface ISplash extends IIdentifiable, Omit<IRenderable, 'type'> {
    type: 'splash';
    text?: string;
}

export interface IIdentifiable {
    id: string;
}

export interface IFacet extends IIdentifiable {
    label: string;
    queryParameter: string;
    values: IFacetValue[];
}

export interface IFacetValue extends IIdentifiable {
    label: string;
    selected: boolean;
    count: number;
}

export interface IFilterPageItem extends IIdentifiable, Omit<IRenderable, 'type'> {
    type: 'filterPageItem';
}

export interface ISortOption extends IIdentifiable {
    selected: boolean;
    label: string;
}

export interface IMetaPage extends Omit<IPage, 'type'>, IMetadata {
    type: 'metaPage';
}

export interface IModulePage extends Omit<IMetaPage, 'type'> {
    type: 'modulePage';
}

export interface IPage
    extends IIdentifiable,
        Omit<IRenderable, 'type'>,
        ILocalizable,
        IGlobalizable {
    type: 'page';
    statusCode: number;
    pageElements: IModules[];
    breadcrumb?: IBreadcrumb;
}

export interface IPersonalizedPage
    extends IIdentifiable,
        Omit<IRenderable, 'type'>,
        ILocalizable,
        IGlobalizable {
    type: 'personalizedPage';
    pageElements: IPageElement[];
}

export interface IP60ModulePage extends Omit<IModulePage, 'type'> {
    type: 'p60ModulePage';
    topAreaPageElements: IModules[];
    hideSideMenu: boolean;
}

export interface IP50ProductDetailPage extends Omit<IModulePage, 'type'> {
    type: 'p50ProductDetailPage';
    product?: IProduct;
}

export interface IP35CategoryDetailPage extends Omit<IModulePage, 'type'> {
    type: 'p35CategoryDetailPage';
    productGroup?: IProductGroup;
    name?: string;
}

export interface IP320ReceiptPage extends Omit<IModulePage, 'type'> {
    type: 'p320ReceiptPage';
}

export interface IP310CheckoutPage extends Omit<IModulePage, 'type'> {
    type: 'p310CheckoutPage';
    companiesWithPickup?: string;
}

export interface IP30CategoryListPage extends Omit<IModulePage, 'type'> {
    type: 'p30CategoryListPage';
    productGroup?: IProductGroup;
}

export interface IP300BasketPage extends Omit<IModulePage, 'type'> {
    type: 'p300BasketPage';
}

export interface IP20FrontPage extends Omit<IModulePage, 'type'> {
    type: 'p20FrontPage';
}

export interface IP210MyFrontPage extends Omit<IModulePage, 'type'> {
    type: 'p210MyFrontPage';
}

export interface IP220MyOrdersPage extends Omit<IModulePage, 'type'> {
    type: 'p220MyOrdersPage';
}

export interface IP230MyPurchasesPage extends Omit<IModulePage, 'type'> {
    type: 'p230MyPurchasesPage';
}

export interface IP240MySupplementaryAgreementsPage extends Omit<IModulePage, 'type'> {
    type: 'p240MySupplementaryAgreementsPage';
}

export interface IP250MyFavouritesPage extends Omit<IModulePage, 'type'> {
    type: 'p250MyFavouritesPage';
}

export interface IP260MyCertificatesPage extends Omit<IModulePage, 'type'> {
    type: 'p260MyCertificatesPage';
}

export interface IP160ArticlePage extends Omit<IP60ModulePage, 'type'>, IArticleItem {
    type: 'p160ArticlePage';
    articleElements: IPageElement[];
    relevantLinks?: ILink[];
}

export interface IP150ErrorPage extends Omit<IModulePage, 'type'> {
    type: 'p150ErrorPage';
}

export interface IP140NotFoundPage extends Omit<IModulePage, 'type'> {
    type: 'p140NotFoundPage';
}

export interface IP130ContactPage extends Omit<IModulePage, 'type'> {
    type: 'p130ContactPage';
}

export interface IP120SearchPage extends Omit<IModulePage, 'type'>, ISearch {
    type: 'p120SearchPage';
}

export interface IModule extends IPageElement, Omit<IRenderable, 'type'> {
    type: 'module';
}

export interface IOptionalPersonalizedModule extends IPersonalizedModule {}

export interface IPersonalizedModule extends IPageElement {}

export interface IRequiredPersonalizedModule extends IPersonalizedModule {}

export interface IM90QuoteModule extends Omit<IModule, 'type'> {
    type: 'm90QuoteModule';
    quote?: string;
    author?: string;
}

export interface IM80MediaAndTextBundleModule extends Omit<IModule, 'type'> {
    type: 'm80MediaAndTextBundleModule';
    headline?: IHeadline;
    callToAction?: ICallToAction;
    numberOfColumns: number;
    mediaAndTextItems: IM85MediaAndTextItem[];
}

export interface IM75MediaAndTextModule extends Omit<IModule, 'type'>, ITheme, IPlacement {
    type: 'm75MediaAndTextModule';
    headline?: IHeadline;
    media: IResponsiveMedia;
    text: IRichText;
    callToActions?: ICallToAction[];
    mediaSize: MediaSize;
    doNotCrop: boolean;
    mediaHorizontalAlignment: HorizontalAlignmentSplit;
}

export interface IM230IPaperModule extends Omit<IModule, 'type'>, ITheme, IPlacement {
    type: 'm230IPaperModule';
    headline?: IHeadline;
    text: IRichText;
    iframe: IIframe;
}

export interface IM21CategoriesModule extends Omit<IModule, 'type'> {
    type: 'm21CategoriesModule';
    headline?: IHeadline;
    buttonText?: string;
    items?: IM21Items[];
}

export interface IM170ProductFilterModule extends Omit<IModule, 'type'> {
    type: 'm170ProductFilterModule';
}

export interface IM160ArticleFilterModule extends Omit<IModule, 'type'> {
    type: 'm160ArticleFilterModule';
    headline?: IHeadline;
    callToAction?: ICallToAction;
    articleItems?: IArticleFilterItem[];
    areaCategories?: ICategory[];
    topicCategories?: ICategory[];
    typeCategories?: ICategory[];
}

export interface IM120AccordionListModule extends Omit<IModule, 'type'> {
    type: 'm120AccordionListModule';
    headline?: IHeadline;
    accordionItems: IAccordionItem[];
}

export interface IM110FormsModule extends Omit<IModule, 'type'> {
    type: 'm110FormsModule';
    text?: IRichText;
    form?: IForm;
}

export interface IM10HeroModule extends Omit<IModule, 'type'>, ITheme, IPlacement, IHeight {
    type: 'm10HeroModule';
    mediaLayout: MediaLayout;
    media?: IResponsiveMedia;
    mediaHorizontalAlignment: HorizontalAlignmentSplit;
    headline?: IHeadline;
    subHeadline?: IHeadline;
    callToAction?: ICallToAction;
}

export interface IM100RichTextModule extends Omit<IModule, 'type'> {
    type: 'm100RichTextModule';
    text?: IRichText;
}

export interface IM130NewsletterModule extends Omit<IModule, 'type'> {
    type: 'm130NewsletterModule';
    apsisFormId?: string;
}

export interface ICallToAction extends ILink {
    style?: string;
}

export interface IFooter extends IIdentifiable {}

export interface IFrame extends IIdentifiable, ILocalizable, Omit<IRenderable, 'type'> {
    type: 'frame';
    header?: IHeader;
    footer?: IFooter;
    mainMenu?: IMainMenu;
    navigation?: INavigationNode;
    searchPage?: ILink;
    basketPage?: ILink;
    checkoutPage?: ILink;
    receiptPage?: ILink;
    market?: IMarket;
    marketSelector?: IMarketSelector;
    settings?: ISiteSettings;
}

export interface IHeader extends IIdentifiable {}

export interface IImageMedia extends Omit<IMedia, 'type'> {
    type: 'imageMedia';
    caption?: string;
    alt?: string;
}

export interface ILink extends IIdentifiable {
    text: string;
    title?: string;
    url?: string;
    target?: string;
}

export interface IMarketLink extends ILink {
    culture?: string;
    marketId?: string;
}

export interface IMedia extends Omit<IRenderable, 'type'>, IIdentifiable {
    type: 'media';
    /**
     * Source of media.
     */
    src: string;
    width: number;
    height: number;
}

export interface IPageElement extends IIdentifiable {}

export interface ISearch extends IIdentifiable {}

export interface IVideoMedia extends Omit<IMedia, 'type'> {
    type: 'videoMedia';
    /**
     * Should the video loop
     */
    loop: boolean;
    /**
     * Should the video start on load
     */
    autoPlay: boolean;
}

export interface IMainMenu extends IIdentifiable {
    navigationTopLevelNodes?: INavigationTopLevelNodes[];
}

export interface IMainNavigationNode extends IIdentifiable {
    link: ILink;
    pageType?: string;
}

export interface INavigation extends IIdentifiable {
    mainMenu?: INavigationNode[];
}

export interface INavigationFooterNode extends INavigationNode {}

export interface INavigationNode extends IIdentifiable {
    link: ILink;
    children?: INavigationNode[];
}

export interface INavigationTopLevelNode extends IMainNavigationNode {}

export interface ICurrency extends IIdentifiable {
    symbol?: string;
}

export interface IMarketSelectorLanguage extends ILink, ILocalizable {}

export interface IMarketSelectorMarket extends IIdentifiable {
    label: string;
    languages: IMarketSelectorLanguage[];
}

export interface IChoiceFormElement extends Omit<IFormElement, 'type'> {
    type: 'choiceFormElement';
    allowMultiSelect: boolean;
    options: ISelectOption[];
}

export interface IDateFormElement extends Omit<IFormElement, 'type'> {
    type: 'dateFormElement';
    timeZoneInfoText?: string;
    dateSettings?: IDateFormElementDateSettings;
    timeSettings?: IDateFormElementTimeSettings;
    showDate: boolean;
    showTimestamp: boolean;
}

export interface IFileUploadFormElement extends Omit<IFormElement, 'type'> {
    type: 'fileUploadFormElement';
    allowedExtensions?: string[];
    fileSizeMaxMb: number;
    allowMultipleFiles: boolean;
}

export interface IForm extends IIdentifiable {
    antiForgery?: IAntiForgery;
    steps?: IFormStep[];
    labels?: IFormLabels;
}

export interface IFormBaseElement extends IIdentifiable {}

export interface IFormElement extends IFormBaseElement, Omit<IRenderable, 'type'> {
    type: 'formElement';
    name?: string;
    label?: string;
    tooltipText?: string;
    inputType: FormElementInputType;
    placeholder?: string;
    value?: string;
    required: boolean;
    requiredMessage?: string;
    regex?: string;
    regexMessage?: string;
}

export interface IFormGroup extends IIdentifiable {
    columns: number;
    elements?: IFormBaseElements[];
    headline?: IHeadline;
    description?: IHeadline;
}

export interface IFormStep extends IIdentifiable {
    label?: string;
    groups?: IFormGroup[];
}

export interface ISelectionFormElement extends Omit<IFormElement, 'type'> {
    type: 'selectionFormElement';
    allowMultiSelect: boolean;
    options: ISelectOption[];
}

export interface ISingleChoiceFormElement extends Omit<IFormElement, 'type'> {
    type: 'singleChoiceFormElement';
    /**
     * Option with Html text
     */
    option?: ISelectOption;
}

export interface IAreaCategory extends Omit<ICategory, 'type'> {
    type: 'areaCategory';
}

export interface ICategory extends IIdentifiable, Omit<IRenderable, 'type'> {
    type: 'category';
    label?: string;
}

export interface ITopicCategory extends Omit<ICategory, 'type'> {
    type: 'topicCategory';
}

export interface ITypeCategory extends Omit<ICategory, 'type'> {
    type: 'typeCategory';
}

export interface ILineItem extends IIdentifiable {
    variation: IVariation;
    quantity: number;
    unitPrice: IMoney;
    discountedUnitPrice?: IMoney;
    linePrice: IMoney;
    discountedLinePrice?: IMoney;
}

export interface IPaymentOption extends IIdentifiable {}

export interface IShippingOption extends IIdentifiable {}

export interface IBasket extends IIdentifiable, ILocalizable, IGlobalizable {
    quantitySum: number;
    totals: ITotals;
    lineItems: ILineItem[];
}

export interface IProfile extends IIdentifiable {
    name?: string;
}

export interface IRenderable {
    type: 'renderable';
}

export interface IM85MediaAndTextItem extends IPlacement, Omit<IM21Item, 'type'> {
    type: 'm85MediaAndTextItem';
    headline?: IHeadline;
    media: IResponsiveMedia;
    text: IRichText;
    callToActions?: ICallToAction[];
}

export interface IM21CategoryDetailItem extends Omit<IM21Item, 'type'> {
    type: 'm21CategoryDetailItem';
    title?: string;
    description?: string;
    link?: ILink;
    itemStyle: M21ItemStyle;
    commerceImage?: string;
    overwriteImage?: IResponsiveMedia;
    productMaterials?: IProductMaterial[];
}

export interface IM21CategoryListItem extends Omit<IM21Item, 'type'> {
    type: 'm21CategoryListItem';
    title?: string;
    description?: string;
    link?: ILink;
    itemStyle: M21ItemStyle;
    commerceImage?: string;
    overwriteImage?: IResponsiveMedia;
}

export interface IM21Item extends Omit<IRenderable, 'type'> {
    type: 'm21Item';
}

export interface IRedirect extends Omit<IRenderable, 'type'> {
    type: 'redirect';
    destination: string;
    permanent: boolean;
}

export interface IVariation extends ILocalizable, IGlobalizable {
    sku: string;
    name: string;
    url?: string;
    image?: IImageMedia;
    images?: IImageMedia[];
    price: IMoney;
    discountedPrice?: IMoney;
    stockStatus: IStockStatus;
}

export interface ILocalizable {
    culture: string;
}

export interface IGlobalizable {
    market: string;
}

export interface IHeadline extends IText {
    tag: HeaderTag;
    style?: HeaderTag;
    hideOnMobile: boolean;
}

export interface IText {
    text: string;
}

export interface IFilter {
    facets: IFacet[];
    sorting: ISorting;
    page: IFilterPage;
}

export interface ISearchResultCategoryViewModel extends IResult {
    category: SearchResultCategory;
    results: ISearchResultItemViewModel[];
    totalResults: number;
}

export interface ISearchResultViewModel extends ISearchResult {
    categories: ISearchResultCategoryViewModel[];
}

export interface IJobVacanciesItem extends IResult {
    publishedDate: string;
    jobTitle?: string;
    locationName?: string;
    businessAreaName?: string;
    link?: ILink;
}

export interface IMarketBanner extends IResult {
    marketLink: IMarketLink;
}

export interface IResult {}

export interface ISearchAutoCompleteResult extends IResult {
    suggestions: string[];
    quickLinks: ILink[];
}

export interface ISearchResult extends IResult {}

export interface IFormSubmitResult extends IResult {
    isSuccess: boolean;
    redirectUrl?: string;
    message?: string;
    validationFail: boolean;
    invalidFields?: IFormElementValidationInfo[];
}

export interface ISimpleMetaFieldViewModel {
    fieldName?: string;
    htmlControlType?: string;
    dataType?: string;
    suffix?: string;
    useAsFacet: boolean;
    visible: boolean;
    displayName?: string;
    url?: string;
    order: number;
}

export interface ITheme {
    /**
     * Background color for the container and content. This will be the background color for the content area when "MediaLayout" is "Split". This is also how it calculates the text color.
     */
    backgroundColor?: string;
    textColor?: string;
}

export interface IMetadata {
    metaTitle: string;
    metaTags?: IMetaTag[];
}

export interface IArticleItem {
    media?: IResponsiveMedia;
    headline?: IHeadline;
    text?: IRichText;
    publishedDate: string;
    /**
     * Used for formatting PublishedDate
     */
    formattingCulture?: string;
    hidePublishedDate: boolean;
    categories?: ICategory[];
}

export interface IPlacement {
    /**
     * Horizontally align content
     */
    horizontalAlignment: HorizontalAlignment;
    /**
     * Vertically align content
     */
    verticalAlignment: VerticalAlignment;
}

export interface IHeight {
    /**
     * The height of the module
     */
    height: ModuleHeight;
}

export interface IResponsiveMedia {
    caption?: string;
    mobile?: IMedia;
    desktop?: IMedia;
    square?: IMedia;
    video?: IVideoMedia;
}

export interface ISubmenuItem {
    tabName?: string;
    modules?: IPageElement[];
}

export interface HeaderSearchBarViewModel {
    placeholder?: string;
}

export interface FooterContactBlockViewModel {
    headline?: string;
    subHeadline?: string;
    links?: ILink[];
}

export interface FooterSocialBlockViewModel {
    headline?: string;
    subHeadline?: string;
    links?: SocialLinkViewModel[];
}

export interface FooterNewsletterBlockViewModel {
    apsisFormId?: string;
}

export interface FooterMetaBlockViewModel {
    copyright?: string;
    links?: ILink[];
}

export interface FooterMenuBlockViewModel {
    menu?: INavigationNode[];
}

export interface IProductSpecification {
    name?: string;
    value?: string;
    suffix?: string;
    url?: string;
    order: number;
}

export interface IProductSpecifications {
    itemnumber?: string;
    type?: string;
    data?: string;
}

export interface ExtraService {
    label?: string;
    editable: boolean;
    sanitizedItemNumber?: string;
}

export interface IBreadcrumb {
    items?: ILink[];
}

export interface IRichText {
    html: string;
}

export enum MediaSize {
    default = 'default',
    small = 'small',
}

export enum HorizontalAlignmentSplit {
    left = 'left',
    right = 'right',
}

export interface IIframe {
    iFrameHorizontalAlignment: HorizontalAlignmentSplit;
    source?: string;
    height: number;
    width: number;
}

export interface IArticleFilterItem extends IArticleItem {
    link?: ILink;
}

export interface IAccordionItem {
    headline: string;
    body: IRichText;
}

export enum MediaLayout {
    full = 'full',
    split = 'split',
}

export interface IMarket {
    id?: string;
    name?: string;
    anonymousCustomerMarketCode?: string;
    defaultLanguage?: string;
    languages?: string[];
    generateSitemap: boolean;
    googleTagManagerId?: string;
    currency?: ICurrency;
    pricesIncludeTax: boolean;
    allowPickUp: boolean;
}

export interface IMarketSelector {
    markets: IMarketSelectorMarket[];
}

export interface ISiteSettings {
    googleTagManagerId?: string;
}

export interface ISelectOption {
    text?: string;
    value?: string;
    checked: boolean;
}

export interface IDateFormElementDateSettings {
    weekdays?: Weekday[];
    firstSelectable: string;
}

export interface IDateFormElementTimeSettings {
    timeIncrementInMinutes: number;
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
}

export interface IAntiForgery {
    token?: string;
    honeypotFieldName?: string;
    reCaptcha?: IReCaptcha;
}

export interface IFormLabels {
    step?: string;
    totalSteps?: string;
    nextStepButton?: string;
    previousStepButton?: string;
    submitButton?: string;
}

export enum FormElementInputType {
    text = 'text',
    textarea = 'textarea',
    number = 'number',
    email = 'email',
    checkbox = 'checkbox',
    radio = 'radio',
    date = 'date',
    file = 'file',
    submit = 'submit',
    select = 'select',
}

export interface IMoney {
    amount: number;
}

export interface ITotals {
    subTotal: number;
    shippingTotal: number;
    taxTotal: number;
    handlingTotal: number;
    total: number;
}

export enum M21ItemStyle {
    contentLayout = 'contentLayout',
    shopLayout = 'shopLayout',
}

export interface IProductMaterial {
    altDescription?: string;
    cssColor?: string;
    name?: string;
}

export interface IStockStatus {
    quantityAvailable: number;
}

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export interface ISorting {
    queryParameter: string;
    options: ISortOption[];
}

export interface IFilterPage {
    total: number;
    take: number;
    skip: number;
    items: IFilterPageItem[];
}

export enum SearchResultCategory {
    categoryDetailPage = 'categoryDetailPage',
    categoryListPage = 'categoryListPage',
    contentPage = 'contentPage',
    articlePage = 'articlePage',
    newsPage = 'newsPage',
    product = 'product',
    accessory = 'accessory',
}

export interface IFormElementValidationInfo {
    id?: string;
    name?: string;
    label?: string;
    validationMessage?: string;
    validator?: string;
}

export interface IMetaTag {
    tag: string;
}

export enum HorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum VerticalAlignment {
    topWithoutPadding = 'topWithoutPadding',
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}

export enum ModuleHeight {
    low = 'low',
    normal = 'normal',
}

export interface SocialLinkViewModel {
    link: ILink;
    socialType: SocialType;
}

export enum Weekday {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export interface IReCaptcha {
    siteKey?: string;
    scriptSource?: string;
}

export enum SocialType {
    facebook = 'facebook',
    instagram = 'instagram',
    linkedIn = 'linkedIn',
    pinterest = 'pinterest',
    youtube = 'youtube',
}
export type IPages =
    | IP60ModulePage
    | IP50ProductDetailPage
    | IP35CategoryDetailPage
    | IP320ReceiptPage
    | IP310CheckoutPage
    | IP30CategoryListPage
    | IP300BasketPage
    | IP20FrontPage
    | IP210MyFrontPage
    | IP220MyOrdersPage
    | IP230MyPurchasesPage
    | IP240MySupplementaryAgreementsPage
    | IP250MyFavouritesPage
    | IP260MyCertificatesPage
    | IP160ArticlePage
    | IP150ErrorPage
    | IP140NotFoundPage
    | IP130ContactPage
    | IP120SearchPage;
export type IModules =
    | ISubmenuModule
    | IM220JobVacanciesModule
    | IM210CookieDeclarationModule
    | IM90QuoteModule
    | IM80MediaAndTextBundleModule
    | IM75MediaAndTextModule
    | IM230IPaperModule
    | IM21CategoriesModule
    | IM170ProductFilterModule
    | IM160ArticleFilterModule
    | IM120AccordionListModule
    | IM110FormsModule
    | IM10HeroModule
    | IM100RichTextModule
    | IM130NewsletterModule;
export type INavigationTopLevelNodes =
    | IN02MainNavigationWithCenterItemsPage
    | IN01MainNavigationWithLeftItemsPage;
export type IFormBaseElements =
    | IChoiceFormElement
    | IDateFormElement
    | IFileUploadFormElement
    | IFormElement
    | ISelectionFormElement
    | ISingleChoiceFormElement;
export type ICategories = IAreaCategory | ITopicCategory | ITypeCategory;
export type IM21Items = IM85MediaAndTextItem | IM21CategoryDetailItem | IM21CategoryListItem;
